var markerSrc = document.getElementById('map').getAttribute('data-src')
ymaps.ready(function () {
    var myMap = new ymaps.Map('map', {
            center: [55.7479427, 37.712076],
            zoom: 17
        }, {
            searchControlProvider: 'yandex#search'
        }),

        // Создаём макет содержимого.
        MyIconContentLayout = ymaps.templateLayoutFactory.createClass(
            '<div style="color: #FFFFFF; font-weight: bold;">$[properties.iconContent]</div>'
        ),

        myPlacemark = new ymaps.Placemark(myMap.getCenter(), {
            // Опции.
            // Необходимо указать данный тип макета.
            iconLayout: 'default#image',
            // Своё изображение иконки метки.
            iconImageHref: `${markerSrc}`,
            // Размеры метки.
            iconImageSize: [46, 72],
            // Смещение левого верхнего угла иконки относительно
            // её "ножки" (точки привязки).
            iconImageOffset: [-23, -38]
        }),
        myPlacemarkWithContent = new ymaps.Placemark([55.7479427, 37.712076], {
            
        }, {
            // Опции.
            // Необходимо указать данный тип макета.
            iconLayout: 'default#image',
            // Своё изображение иконки метки.
            iconImageHref: `${markerSrc}`,
            // Размеры метки.
            iconImageSize: [46, 72],
            // Смещение левого верхнего угла иконки относительно
            // её "ножки" (точки привязки).
            iconImageOffset: [-23, -38],// Смещение слоя с содержимым относительно слоя с картинкой.
            iconContentOffset: [15, 15],
            // Макет содержимого.
            iconContentLayout: MyIconContentLayout
        });

    myMap.geoObjects
        .add(myPlacemarkWithContent);
});



// var markerSrc = document.getElementById('map').getAttribute('data-src')
// console.log(markerSrc)
//     var opts = {			
//         center: {
//             lat : 55.7479427,
//             lng : 37.712076,
//         },
//         maxZoom: 20,
//         minZoom: 5,
//         zoom: 17,
//         styles: [
//             {
//                 "featureType": "water",
//                 "elementType": "geometry",
//                 "stylers": [
//                     {
//                         "color": "#e9e9e9"
//                     },
//                     {
//                         "lightness": 17
//                     }
//                 ]
//             },
//             {
//                 "featureType": "landscape",
//                 "elementType": "geometry",
//                 "stylers": [
//                     {
//                         "color": "#f5f5f5"
//                     },
//                     {
//                         "lightness": 20
//                     }
//                 ]
//             },
//             {
//                 "featureType": "road.highway",
//                 "elementType": "geometry.fill",
//                 "stylers": [
//                     {
//                         "color": "#ffffff"
//                     },
//                     {
//                         "lightness": 17
//                     }
//                 ]
//             },
//             {
//                 "featureType": "road.highway",
//                 "elementType": "geometry.stroke",
//                 "stylers": [
//                     {
//                         "color": "#ffffff"
//                     },
//                     {
//                         "lightness": 29
//                     },
//                     {
//                         "weight": 0.2
//                     }
//                 ]
//             },
//             {
//                 "featureType": "road.arterial",
//                 "elementType": "geometry",
//                 "stylers": [
//                     {
//                         "color": "#ffffff"
//                     },
//                     {
//                         "lightness": 18
//                     }
//                 ]
//             },
//             {
//                 "featureType": "road.local",
//                 "elementType": "geometry",
//                 "stylers": [
//                     {
//                         "color": "#ffffff"
//                     },
//                     {
//                         "lightness": 16
//                     }
//                 ]
//             },
//             {
//                 "featureType": "poi",
//                 "elementType": "geometry",
//                 "stylers": [
//                     {
//                         "color": "#f5f5f5"
//                     },
//                     {
//                         "lightness": 21
//                     }
//                 ]
//             },
//             {
//                 "featureType": "poi.park",
//                 "elementType": "geometry",
//                 "stylers": [
//                     {
//                         "color": "#dedede"
//                     },
//                     {
//                         "lightness": 21
//                     }
//                 ]
//             },
//             {
//                 "elementType": "labels.text.stroke",
//                 "stylers": [
//                     {
//                         "visibility": "on"
//                     },
//                     {
//                         "color": "#ffffff"
//                     },
//                     {
//                         "lightness": 16
//                     }
//                 ]
//             },
//             {
//                 "elementType": "labels.text.fill",
//                 "stylers": [
//                     {
//                         "saturation": 36
//                     },
//                     {
//                         "color": "#333333"
//                     },
//                     {
//                         "lightness": 40
//                     }
//                 ]
//             },
//             {
//                 "elementType": "labels.icon",
//                 "stylers": [
//                     {
//                         "visibility": "off"
//                     }
//                 ]
//             },
//             {
//                 "featureType": "transit",
//                 "elementType": "geometry",
//                 "stylers": [
//                     {
//                         "color": "#f2f2f2"
//                     },
//                     {
//                         "lightness": 19
//                     }
//                 ]
//             },
//             {
//                 "featureType": "administrative",
//                 "elementType": "geometry.fill",
//                 "stylers": [
//                     {
//                         "color": "#fefefe"
//                     },
//                     {
//                         "lightness": 20
//                     }
//                 ]
//             },
//             {
//                 "featureType": "administrative",
//                 "elementType": "geometry.stroke",
//                 "stylers": [
//                     {
//                         "color": "#fefefe"
//                     },
//                     {
//                         "lightness": 17
//                     },
//                     {
//                         "weight": 1.2
//                     }
//                 ]
//             }
//         ],
//         mapTypeId: 'roadmap',
//     };

    
//     opts.clickableIcons = true;
//     opts.disableDoubleClickZoom = false;
//     opts.draggable = true;
//     opts.keyboardShortcuts = true;
//     opts.scrollwheel = true;
    

    
//     var setControlOptions = function(key, enabled, position, style, mapTypeIds){
//         opts[key + 'Control'] = enabled;
//         opts[key + 'ControlOptions'] = {
//             position: google.maps.ControlPosition[position],
//             style: google.maps.MapTypeControlStyle[style],
//             mapTypeIds: mapTypeIds
//         };
//     };
    
        
//     setControlOptions('fullscreen',false,'DEFAULT','',null);
    
        
//     setControlOptions('mapType',false,'DEFAULT','DEFAULT',["roadmap","satellite","terrain"]);
    
        
//     setControlOptions('rotate',false,'DEFAULT','',null);
    
        
//     setControlOptions('scale',false,'','',null);
    
        
//     setControlOptions('streetView',false,'DEFAULT','',null);
    
        
//     setControlOptions('zoom',false,'DEFAULT','',null);
    

//     var map = new google.maps.Map(document.getElementById('map'), opts);

//     (function(){
//         var markerImage = new google.maps.MarkerImage(
//             `${markerSrc}`,
//             new google.maps.Size(46,72), //size
//             null, //origin
//             new google.maps.Point(23,36), //anchor
//         );
//         var marker = new google.maps.Marker({
//             position: new google.maps.LatLng(55.7479427, 37.712076),
//             map: map,
//             icon: markerImage 
//         });
//         google.maps.event.addListener(map, 'zoom_changed', function(){
//             var pixelSizeAtZoom0 = 9.5; 
//             var posX = 2.74;
//             var posY = 8.63;
//             var zoom = map.getZoom();
//             var relativePixelSize = pixelSizeAtZoom0*zoom;
             

//             map.panTo(new google.maps.LatLng(55.7479427, 37.712076));
//         });
//     })();